import { Container} from "react-bootstrap"
import bulletPoint from "../assets/img/bulletPoint.svg"
import { useEffect } from "react";

export const ProjectsTimeline = (props) => {

    
    const projects = props.projectsArray

    useEffect(() => {
        
        const revealSkills = () => {
            var revealElement = document.querySelectorAll(".reveal")

            for (var i = 0; i < revealElement.length; i++) {
                var windowHeight = window.innerHeight;
                var revealTop = revealElement[i].getBoundingClientRect().top;
                var revealPoint = 150;

                if (revealTop < windowHeight - revealPoint) {
                    revealElement[i].classList.add("active")
                }
            }
        }

        revealSkills()

        window.addEventListener("scroll", revealSkills);

        return() => window.removeEventListener("scroll", revealSkills);
    });
    
    return (
        <div className={"timeline reveal timelineModule"+ props.moduleNumber}>
            {
                projects.map((project,index) => {
                    return (
                        <Container key = {index}>
                            <img src={bulletPoint} alt="Timeline point"></img>
                            <div className="text-box">
                                <h2>{project.title}</h2>
                                {project.technologies !== project.title && <h3>{project.technologies}</h3>}
                                <small>{project.timePeriod}</small>
                                <p>{project.description}</p>
                                <span className="timeline-bubble-arrow"></span>
                            </div>
                        </Container>
                    )
                })
            }
        </div>
    )
}