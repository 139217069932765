import { Container, Row, Col, Nav, Tab} from "react-bootstrap"
import { ProjectsTimeline } from "./ProjectsTimeline"
import projImgICNF from "../assets/img/icnf-proj.png"
import projImgOneX from "../assets/img/onex-proj.png"
import projImg3CA from "../assets/img/3CA-proj.png"
import projImgUniAI from "../assets/img/ai_uni-proj.png"
import projImgUniCurso from "../assets/img/ubi-proj.png"

export const Projects = () => {
    const work_projects = [
        {
            title: "ICNF",
            technologies: "OutSystems, JS, CSS3",
            description: "ICNF system that integrates applications to simplify and make processes quicker.",
            timePeriod: "2021 - 2022",
            imgUrl: projImgICNF
        },
        {
            title: "One-X",
            technologies: "Laravel, jQuery, MySQL",
            description: "Excell Communications system that allows management of a company, from ticket management, to finance and human resources.",
            timePeriod: "2022 - 2024",
            imgUrl: projImgOneX
        }
    ]

    const uni_projects = [
        {
            title: "Web Computing Degree",
            technologies: "Web Computing Degree",
            description: "Web-based Computer Science, with a special focus on the relation between the applications and services developed and their design.",
            timePeriod: "2018 - 2021",
            imgUrl: projImgUniCurso
        }, 
        {
            title: "3CA Management System and Blog",
            technologies: "PHP, HTML 5, CSS3",
            description: "System that allows management of 3CA's room at UBI, requests to use the room and a blog to share information.",
            timePeriod: "2021",
            imgUrl: projImg3CA
        }
    ]

    const uni_projects2 = [
        {
            title: "Computer Science and Engineering MSc",
            technologies: "Computer Science and Engineering MSc",
            description: "Computer Science and Engineering goal was to develop a solid and outstanding background in Computer Science, fortifying the Full Stack knowledge aquired during previous course.",
            timePeriod: "2021 - 2024",
            imgUrl: projImgUniCurso
        },
        {
            title: "Process Automation using Artificial Intelligence",
            technologies: "Python, Machine Learning",
            description: "Development of A.I. module that reads history data of tickets and allocates the best field tech to solve the problems.",
            timePeriod: "2024",
            imgUrl: projImgUniAI
        }
    ]
    
    return (
        <section className="project" id="projects">
            <Container>
                <Row>
                    <Col>
                        <h2>Experience</h2>
                        <p>Here you can find my developing experience, from previous Work Experiences to my University Journey. Soon also some Personal Projects :D</p>
                        <Tab.Container id="projects-tabs" defaultActiveKey="first-project">
                            <Nav variant="pills" className="nav-pills mb-5 justify-content-center align-items-center" id="pills-tab">
                                <Nav.Item>
                                    <Nav.Link eventKey="first-project">Work</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="second-project">University</Nav.Link>
                                </Nav.Item>
                            </Nav>

                            <Tab.Content>
                                <Tab.Pane eventKey="first-project">
                                <Row>
                                    <ProjectsTimeline projectsArray={work_projects} moduleNumber="1"/>
                                </Row>
                            </Tab.Pane>
                            <Tab.Pane eventKey="second-project">
                                <Row>
                                    <ProjectsTimeline projectsArray={uni_projects} moduleNumber="1"/>
                                    <ProjectsTimeline projectsArray={uni_projects2} moduleNumber="2"/>
                                </Row>
                            </Tab.Pane>
                            </Tab.Content>
                        </Tab.Container>
                    </Col>
                </Row>
            </Container>
        </section>
    )
}