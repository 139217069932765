import {Container, Nav, Navbar} from  'react-bootstrap'
import {useEffect, useState, useRef} from 'react'
import navIcon1 from '../assets/img/nav-icon1.svg'
import githubIcon from '../assets/img/github.svg'
import { HashLink } from 'react-router-hash-link';
import {
  BrowserRouter as Router
} from "react-router-dom";


export const NavBar = () => {
    // Navbar opening control, to fix closing outside bug
    const [isNavbarOpen, setIsNavbarOpen] = useState(false);
    const warpNavBar = useRef("navbar")
    const warpNavbarToggle = useRef("navbarToggle")

    const handleToggle = () => setIsNavbarOpen(!isNavbarOpen);

    const handleClose = () => setIsNavbarOpen(false);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (!warpNavBar?.current?.contains(event.target)) {
                if (warpNavbarToggle?.current?.contains(event.target)) {
                    handleToggle()
                } else {
                    handleClose();
                }
               
            }
        };
        document.addEventListener("mousedown", handleClickOutside);

        return() => document.removeEventListener("mousedown", handleClickOutside)
    });

    // Scroll control, to change navbar opacity
    const [activeLink, setActiveLink] = useState("home");
    const [hasScrolled, setHasScrolled] = useState(false);
    useEffect(() => {
        const onScroll = () => {
            if(window.scrollY > 50) {
                setHasScrolled(true)
            } else {
                setHasScrolled(false)
            }
        }

        window.addEventListener("scroll", onScroll)

        return() => window.removeEventListener("scroll", onScroll)
    }, [])

    const onUpdateActiveLink = (linkValue) => {
        setActiveLink(linkValue)

        setIsNavbarOpen(false)
    }
    return (
        <Router>
            <Navbar collapseOnSelect expand="md" className={hasScrolled ? "scrolled" : ""}>
                <Container>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" ref={warpNavbarToggle}>
                    <span className='navbar-toggler-icon'></span>
                    </Navbar.Toggle>
                    <Navbar.Collapse id="basic-navbar-nav" onExited={handleClose} in={isNavbarOpen} appear={isNavbarOpen} ref={warpNavBar}>
                        <Nav className="ms-auto">
                            <Nav.Link eventKey="home" href="#home" className={activeLink === 'home' ? "active navbar-link" : "navbar-link"} onClick={() => onUpdateActiveLink("home")}>Home</Nav.Link>
                            <Nav.Link eventKey="skills" href="#skills" className={activeLink === 'skills' ? "active navbar-link" : "navbar-link"} onClick={() => onUpdateActiveLink("skills")}>Skills</Nav.Link>
                            <Nav.Link eventKey="projects" href="#projects" className={activeLink === 'projects' ? "active navbar-link" : "navbar-link"} onClick={() => onUpdateActiveLink("projects")}>Projects</Nav.Link>
                        </Nav>
                        <span className='navbar-buttons'>
                            <div className='social-icon' onClick={() => handleClose()}>
                                <a target="_blank" rel="noreferrer" href='https://github.com/CapHope'><img src={githubIcon} alt="GitHub"/></a>
                            </div>
                            <div className='social-icon'  onClick={() => handleClose()}>
                                <a target="_blank" rel="noreferrer" href='https://www.linkedin.com/in/joao-lindeza/'><img src={navIcon1} alt="LinkedIn"/></a>
                            </div>
                            <HashLink to='#connect'  onClick={() => handleClose()}>
                                <button className='vvd'><span>Contact Me!</span></button>
                            </HashLink>
                        </span>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        </Router>
    )
}
