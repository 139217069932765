import './App.scss';
import {useEffect, useState} from 'react';
import { NavBar } from './components/Navbar';
import { Banner } from './components/Banner';
import { Projects } from './components/Projects';
import 'bootstrap/dist/css/bootstrap.min.css'
import { Contact } from './components/Contact';
import { Footer } from './components/Footer';
import { Skills } from './components/Skills';

function App() {

  
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    
  const handleResize = () => {
      setWindowWidth(window.innerWidth)
    }
    
  useEffect(() => {
      window.addEventListener("resize", handleResize)
  })

  
  return (
    <div className="App">
      <NavBar />
      <Banner />
      <Skills />
      <Projects windowWidth={windowWidth}/>
      <Contact windowWidth={windowWidth}/>
      <Footer />
    </div>
  );
}

export default App;
